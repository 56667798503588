import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'RobuxSwap',
  description:
    'The most popular AMM on BSC by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by RobuxSwap), NFTs, and more, on a platform you can trust.',
  image: 'https://RobuxSwap.finance/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('RobuxSwap')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('RobuxSwap')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('RobuxSwap')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('RobuxSwap')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('RobuxSwap')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('RobuxSwap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('RobuxSwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('RobuxSwap')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('RobuxSwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('RobuxSwap')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('RobuxSwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('RobuxSwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('RobuxSwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('RobuxSwap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('RobuxSwap')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('RobuxSwap')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('RobuxSwap')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('RobuxSwap')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('RobuxSwap Info & Analytics')}`,
        description: 'View statistics for RobuxSwap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('RobuxSwap Info & Analytics')}`,
        description: 'View statistics for RobuxSwap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('RobuxSwap Info & Analytics')}`,
        description: 'View statistics for RobuxSwap exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('RobuxSwap')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('RobuxSwap')}`,
      }
    case '/nfts/activity':
      return {
        title: `${t('Activity')} | ${t('RobuxSwap')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Profile')} | ${t('RobuxSwap')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')} | ${t('RobuxSwap')}`,
      }
    default:
      return null
  }
}
